@font-face {
font-family: 'PretendardVariable';
src: url(/_next/static/media/ff840cfebfb63b0c-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 45 920;
}@font-face {font-family: 'PretendardVariable Fallback';src: local("Arial");ascent-override: 93.76%;descent-override: 23.75%;line-gap-override: 0.00%;size-adjust: 101.55%
}.__className_6db6ba {font-family: 'PretendardVariable', 'PretendardVariable Fallback'
}.__variable_6db6ba {--font-pretendard-variable: 'PretendardVariable', 'PretendardVariable Fallback'
}

